import { StyledFooter } from "./Footer.styles";

import FacebookLogo from "assets/social/facebook.svg";

import LinkedInLogo from "assets/social/linkedin.svg";

import TwitterLogo from "assets/social/twitter.svg";

import InstagramLogo from "assets/social/instagram.svg";

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <a
          href="https://www.linkedin.com/company/collect-africa/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LinkedInLogo} alt="linkedin-logo" />
        </a>

        <a
          href="https://twitter.com/collectafrica_"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TwitterLogo} alt="twitter-logo" />
        </a>

        <a
          href="https://www.instagram.com/collect.africa/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={InstagramLogo} alt="instagram-logo" />
        </a>

        <a
          href="https://www.facebook.com/collectafrica"
          target="_blank"
          rel="noreferrer"
        >
          <img src={FacebookLogo} alt="facebook-logo" />
        </a>
      </div>

      <div>
        <p>
          © 2021 Collect. All rights reserved -
          <a href="https://www.collect.africa/privacy"> Privacy Policy</a>
        </p>
      </div>
    </StyledFooter>
  );
};

export default Footer;
