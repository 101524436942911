import styled from "styled-components";

export const StyledSimulator = styled.div`
  width: 300px;

  background: white;
  border: 1rem solid #eaf1fd;
  border-top: 4rem solid #eaf1fd;
  border-bottom: 4rem solid #eaf1fd;
  border-radius: 2.3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledSimulatorContent = styled.div`
  overflow-y: scroll;

  height: 370px;
  padding-bottom: 16px;
  background: #ffffff;
`;

export const SimulatorTouch = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #8080802e;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 20%);
`;

export const SimulatorSpeaker = styled.span`
  width: 80px;
  border-radius: 10px;
  display: block;
  border: 3px solid #8080802e;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -400%);
`;
