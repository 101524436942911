import {
  SimulatorSpeaker,
  SimulatorTouch,
  StyledSimulator,
  StyledSimulatorContent,
} from "./Simulator.styles";

interface SimulatorProps {
  children: JSX.Element;
}
const Simulator: React.FC<SimulatorProps> = ({ children }) => {
  return (
    <StyledSimulator>
      <StyledSimulatorContent>{children}</StyledSimulatorContent>

      <SimulatorSpeaker />
      <SimulatorTouch />
    </StyledSimulator>
  );
};

export default Simulator;
