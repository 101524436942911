import Footer from "components/Footer/Footer";

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => (
  <>
    {children}
    <Footer />
  </>
);
export default Layout;
