import styled, { keyframes } from "styled-components";

const rotate = keyframes`  
    to {
      transform: rotate(360deg);
    }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  border-top-color: #fec055;
  animation: ${rotate} 0.5s linear infinite;
`;
