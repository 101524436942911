import { TransactionHistoryDto } from "pages/Payment/types";
import { PaymentDto } from "services/transfer";
import { AccountDetail } from "utils/types";

export const formatAmount = (amount: string) => {
  if (amount) {
    const amountInParts = amount.toString().split(".");

    amountInParts[0] = amountInParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return amountInParts.join(".");
  }

  return 0;
};

export const clearUserData = () => {
  localStorage.removeItem("AccountDetail");

  localStorage.removeItem("TransactionHistory");
};

export const updateTransactionDetails = (payload: PaymentDto) => {
  const accountDetail = localStorage.getItem("AccountDetail");

  const account: AccountDetail = accountDetail && JSON.parse(accountDetail);

  const updatedAccountDetails: AccountDetail = {
    name: account.name,
    number: account.number,
    amount: +account.amount - +payload.amount,
  };

  localStorage.setItem("AccountDetail", JSON.stringify(updatedAccountDetails));

  const transactionHistory: TransactionHistoryDto = {
    ref: payload.payment_reference,
    date: new Date().toDateString(),
    amount: `₦${payload.amount}`,
    recipient: payload.account_number,
  };

  const transactions = localStorage.getItem("TransactionHistory");

  const transactionDetails = transactions ? JSON.parse(transactions) : [];

  transactionDetails.unshift(transactionHistory);

  localStorage.setItem(
    "TransactionHistory",
    JSON.stringify(transactionDetails)
  );
};

export const getAccountDetails = () => {
  const accountDetail = localStorage.getItem("AccountDetail");

  const account = accountDetail && JSON.parse(accountDetail);

  return account;
};

export const mountUserAccountDetails = () => {
  const accountDetail = getAccountDetails();

  if (!accountDetail) {
    const accountDetail: AccountDetail = {
      name: "Collect Staging",
      number: "0531136732",
      amount: 100000000,
    };

    localStorage.setItem("AccountDetail", JSON.stringify(accountDetail));
  }
};

export const getTransactionHistory = () => {
  const transactionHistory = localStorage.getItem("TransactionHistory");

  const transactions = transactionHistory && JSON.parse(transactionHistory);

  return transactions;
};
