import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";

import Simulator from "hoc/Simulator/Simulator";
import Layout from "hoc/Layout/Layout";
import Loader from "components/Loader/Loader";

import { clearUserData, mountUserAccountDetails } from "utils";

import { GlobalStyle } from "./index.styles";

const LazyDashboardPage = lazy(() => import("pages/Dashboard/Dashboard"));
const LazyPaymentPage = lazy(() => import("pages/Payment/Payment"));
const LazySuccessPage = lazy(() => import("pages/Success/Success"));

const App = () => {
  mountUserAccountDetails();

  useEffect(() => {
    window.addEventListener("unload", clearUserData);

    return () => {
      window.removeEventListener("unload", clearUserData);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <Layout>
        <Simulator>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<LazyDashboardPage />} />
                <Route path="/payment" element={<LazyPaymentPage />} />
                <Route path="/success" element={<LazySuccessPage />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </Simulator>
      </Layout>
    </>
  );
};

export default App;
